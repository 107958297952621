import throttle from 'lodash-es/throttle';
import initHeaderMobile from './header-mobile';
import initHeaderIcons from './header-icons/index';
import './index.sass';
import './header-drop/index';

export default () => {
  initHeaderMobile();
  initHeaderIcons();

  const $dropDownBlock = $('.header__icon-drop');
  const $header = $('.header');
  const $headerContainer = $('.header__container');
  let lastScrollTop = 0;

  $('.js-search-comment-form').find('#query').on('input', function() {
    const $form = $(this).closest('.js-search-comment-form');
    const $submitBtn = $form.find('.input-place__submit');

    if ($(this).val().length > 0) {
      return $submitBtn.removeAttr('disabled');
    } else {
      return $submitBtn.attr('disabled', 'disabled');
    }
  });

  // $('.input-place--search input').on('focus', () => {
  //   $('.header__form').addClass('header__form--width')
  // })
  //
  // $('.input-place--search input').on('blur', () => {
  //   $('.header__form').removeClass('header__form--width')
  // })

  const handleHeaderFixed = () => {
    const headerHeight = $headerContainer.outerHeight();
    const st = window.pageYOffset;

    if (Math.abs(lastScrollTop - st) <= 1) return;

    if (st < lastScrollTop && st > headerHeight) {
      $header.addClass('header--animation');
    } else if ((st + $(window).height()) + headerHeight < $(document).height()) {
      $header.removeClass('header--animation');
    }

    if (st >= 80) {
      $header.addClass('header--fixed');
      if($dropDownBlock.hasClass('header__icon-drop--open')) {
        $dropDownBlock.removeClass('header__icon-drop--open')
      }
    }

    if (st <= 250) {
      $header.removeClass('header--fixed');
      $header.removeClass('header--animation');
    }
    lastScrollTop = st;
  }

  if($(window).outerWidth() >= 1080) {
    $(window).on('scroll', throttle(() => {
        handleHeaderFixed();
      }, 150)
    )
  } else {
    $header.removeClass('header--fixed');
    $header.removeClass('header--animation');
  }

  $(window).on('resize', throttle(() => {
    if($(window).outerWidth() >= 1080) {
      $(window).on('scroll', throttle(() => {
          handleHeaderFixed();
        }, 150)
      )
    } else {
      $(window).off('scroll');
      $header.removeClass('header--fixed');
      $header.removeClass('header--animation');
    }
    }, 150)
  )

  const initSearch = () => {
    const $form = $('.header__form');
    $('.js-search-form').each((i, item) => {
      $(item).find('#query, #query_mobile').on('input propertychange', function() {
        var $form, $submitBtn;
        $form = $(this).closest('.js-search-form');
        $submitBtn = $form.find('.input-place__submit');
        if ($(this).val().length > 0) {
          $form.addClass('header__form--width');
          return $submitBtn.removeAttr('disabled');
        } else {
          $form.removeClass('header__form--width');
          return $submitBtn.attr('disabled', 'disabled');
        }
      });

    })
  }
  initSearch();

  $('.js-header-mobile-add').on('change', function () {
     window.location.href = $(this).val();
  });
}