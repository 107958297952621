import _ from 'underscore';
import './index.sass';

export default () => {
  var modalCallBacks;

  window.showError = function(message, template) {
    var modalTemplate;
    if (template == null) {
      template = '#modal-error';
    }
    $('body').css('overflow', 'hidden');
    modalTemplate = _.template($(template).html());
    return $('#modal-container').html(modalTemplate({
      message: message
    }));
  };

  window.showMessage = function(message, template) {
    var modalTemplate;
    if (template == null) {
      template = '#modal-message';
    }
    $('body').css('overflow', 'hidden');
    modalTemplate = _.template($(template).html());
    return $('#modal-container').html(modalTemplate({
      message: message
    }));
  };

  window.showQuestion = function(question, callback) {
    var modalTemplate;
    $('body').css('overflow', 'hidden');
    modalTemplate = _.template($('#modal-question').html());
    $('#modal-container').html(modalTemplate({
      question: question
    }));
    return $('#modal-container').find('.js-button-ok').click(function() {
      callback();
      return $('.modal-close').trigger('click');
    });
  };

  modalCallBacks = function() {
    $('body').on('click', '.modal-close, a[data-action="modal-close"]', function() {
      $('body').css('overflow', 'auto');
      $('.modal-wrp').fadeOut(400);
      if ($(this).data('work')) {
        history.replaceState({}, {}, window.location.href.split('?')[0]);
      }
      return setTimeout(function(_item) {
        return $('.modal-window').hide();
      }, 400);
    });
    return false;
  };

  $(function() {
    return modalCallBacks();
  });
}