export default () => {
    $(document).off('click', '#propose-contest-button').on('click', '#propose-contest-button', function(e) {
        e.preventDefault();
        var popup
        $.magnificPopup.open( {
            items: {
                src: '#propose-contest-popup'
            },
            type: 'inline',
            closeBtnInside: true,
            closeOnBgClick: true
        });
        popup = $("#propose-contest-popup");
        popup.find('[data-role="title"]').text("Предложить конкурс");
        popup.find('[data-role="subtitle"]').text("Опишите максимально подробно условия конкурса который хотите предложить.");
        setTimeout(function() {
            return $('#propose-contest-popup').find(':submit').removeClass('disabled').removeAttr("disabled");
        }, 200);
        return false;
    });




}