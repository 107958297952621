import 'fonts';
import 'lazysizes';

import Rails from 'rails-ujs';
Rails.start();
import I18n from 'i18n';
import Layout from './layout';
window.I18n = I18n;

import 'jquery-ui/ui/widgets/draggable';

import 'magnific-popup/dist/jquery.magnific-popup.min'
import 'magnific-popup/dist/magnific-popup.css'

import 'blocks/hint';
import 'blocks/title';
import 'blocks/head';
import 'blocks/section';
import 'blocks/buttons';
import 'blocks/avatar-image';
import 'blocks/nav';
import 'blocks/socials';
import 'blocks/link';

import initInput from 'blocks/input';
import initLoginPopup from 'blocks/login-popup';
import initBuyPopup from 'blocks/buy-popup';
import initProposeContestPopup from 'blocks/propose-contest-popup';
import initModal from 'blocks/modal';
import {profilePopups} from 'blocks/popups';

let promiseHandler;

if(window.I18n) {
  I18n.loaded = new Promise((resolve, reject) => {
    promiseHandler = resolve;
  })
  import("./translations.js").catch(error => {
    console.log(error);
  }).finally(() => {
    promiseHandler();
  })
  import("./routes.js").then(module => {
    window.Routes = module;
  }).catch(error => {
    console.log(error);
  })
}

$(() => {
  Layout();
  initBuyPopup();
  initModal();
  profilePopups();
  initProposeContestPopup();

  $('.input-place').length && initInput();
  $('.js-login').length && initLoginPopup();
});
