import './index.sass'

export default () => {
  const $dropDownBlock = $('.header-icons__drop');

  const closeDropDown = (e) => {
    const tar = e.target;
    const itsMenu = tar === $dropDownBlock || tar.closest('.header-icons__drop');
    if (!itsMenu) {
      $dropDownBlock.removeClass('header-icons__drop--open');
      $(document).off('click', closeDropDown);
    }
  }

  $('.js-show-drop').on('click', (e) => {
    e.preventDefault();
    if(!$dropDownBlock.hasClass('header-icons__drop--open')) {
      $dropDownBlock.addClass('header-icons__drop--open');
      $(document).on('click', closeDropDown);
    } else {
      $dropDownBlock.removeClass('header-icons__drop--open');
      $(document).off('click', closeDropDown);
    }
  })
}