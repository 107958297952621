export default () => {
    $(document).on('click', '.js-image-page', function() {
        window.history.pushState('', '', $(this).data('back-url'));
        return true;
    });

    $(document).off('click', '#buy_message').on('click', '#buy_message', function(e) {
        e.preventDefault();
        var author_id, popup, product_info, title, url;
        $.magnificPopup.open( {
            items: {
                src: '#buy-message-popup'
            },
            type: 'inline',
            closeBtnInside: true
        });
        popup = $("#buy-message-popup");
        if (gon.buy) {
            title = gon.buy.title;
            url = gon.buy.url;
        } else {
            product_info = $(e.target).closest(".card__info");
            title = product_info.find(".card__title").text();
            url = $(e.target).closest(".card").find(".card__preview > a").prop("href");
            author_id = product_info.find("#author_id").text();
            popup.find('#receiver_id').val(author_id);
        }
        popup.find('[data-role="title"]').text("Купить: " + title);
        popup.find('[data-role="subtitle"]').text("Условия приобретения, доставки и детали уточняйте напрямую у автора");
        setTimeout(function() {
            popup.find('[data-role="textarea"]').val("Здравствуйте, интересует ваш товар(услуга) \n" + title + "\n" + url);
            return $('#buy-message-popup').find(':submit').removeClass('disabled').removeAttr("disabled");
        }, 200);
        return false;
    });
}