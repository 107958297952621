import './index.sass';
import './buy-message-popup/index.sass';
import initSubscribersPopup from "../subscribers-popup";

const profilePopups = function() {
  let csrfToken;
  $('meta').each((index,item) => {
    const $target = $(item);
    if($target.attr('name') === 'csrf-token') {
      csrfToken = $target.attr('content')
      return csrfToken;
    }
  })

  let popupOpen;
  if ($('.profile-modals').length === 0) {
    return;
  }
  popupOpen = function(section) {
    $('.profile-modals').fadeIn(300);
    return setTimeout(function() {
      $('.profile-modals #' + section).fadeIn(300);
      $('body').css('overflow', 'hidden');
      $('#message-popup').find('textarea').val('');
      return $('#message-popup').find(':submit').addClass('disabled');
    }, 150);
  };
  // $('body').on('click', '#bio-open, .bio-short', function() {
  //   if (!$(this).hasClass('js-without_popup')) {
  //     popupOpen('biography-popup');
  //   }
  //   return false;
  // });
  $('body').on('click', '#message-popup', function() {
    $('#biography-popup').fadeOut(300);
    setTimeout(function() {
      return $('#message-popup').fadeIn(250);
    }, 300);
    // return false;
  });
  $('body').on('click', '#profile-message', function() {
    popupOpen('message-popup');
    return false;
  });
  // $('body').on('click', '#achievements_open', function() {
  //   popupOpen('achievments-popup');
  //   return false;
  // });
  $('body').on('click', '#user_followers_open', function() {
    if ($(this).data('count') > 0) {
      $.ajax({
        url: $(this).data('url'),
        type: "GET",
        headers: {
          "X-CSRF-Token": $('meta[name=csrf-token]').attr('content'),
        },
        success: function(data) {
          if ($('body > #user-followers-popup').length === 0) {
            $('body').append(data);
            initSubscribersPopup();
          } else {
            $('body > #user-followers-popup').replaceWith(data);
            initSubscribersPopup();
          }
        }
      });
      $('body').css('overflow', 'hidden');
      // popupOpen('user-followers-popup');
    }
    return false;
  });
  $('body').on('click', '#following_users_open', function() {
    if ($(this).data('count') > 0) {
      $.ajax({
        url: $(this).data('url'),
        type: "GET",
        headers: {
          "X-CSRF-Token": $('meta[name=csrf-token]').attr('content'),
        },
        success: function(data) {
          if ($('body > #following-users-popup').length === 0) {
            $('body').append(data);
            initSubscribersPopup();
          } else {
            $('body > #following-users-popup').replaceWith(data);
            initSubscribersPopup();
          }
        }
      });
      $('body').css('overflow', 'hidden');
      // popupOpen('following-users-popup');
    }
    return false;
  });
  $('body').on('click', '.popups-close, .profile-modals', function(e) {
    const $modalUsers = $('#following-users-popup, #user-followers-popup');
    if (!$(e.target).is('.follow-button')) {
      $('body').css('overflow', 'auto');
      if($modalUsers.length) {
        $modalUsers.css('display', 'none');
      }
      $('.profile-modals').fadeOut(300);
      return setTimeout(function() {
        return $('.profile-modals > div').hide();
      }, 300);
    }
  });
  $('body').on('click', '.profile-modals > div', function(e) {
    if (!$(e.target).is('.follow-button')) {
      return e.stopPropagation();
    }
  });
  $('body').on('click', '.js-access-lock-button', function() {
    popupOpen('access-lock-popup');
    return false;
  });
  $('body').on('click', '.js-pro-account-button', function() {
    popupOpen('pro-account-popup');
    return false;
  });
  $('body').on('click', '.js-change-password-button', function() {
    popupOpen('change-password-popup');
    return false;
  });
  return $('#change-password-popup').find('form').on('ajax:success', function(e, data) {
    var passwordField;
    if (e.detail[0].error) {
      passwordField = $(this).find('#new_password');
      passwordField.addClass('error');
      return passwordField.after('<div class="bubble-error bubble-error-change-pass" >' + e.detail[0].error + '</div>');
    } else {
      return window.location = e.detail[0].path;
    }
  });
};

export {profilePopups};
