import './base';
import initFooter from './footer';
import './grid';
import './settings-menu';
import initHeader from './header';

export default () => {
    initHeader();
    initFooter();
}
