import './index.sass';
import throttle from "lodash-es/throttle";

export default () => {
  const $modal = $('#user-followers-popup, #following-users-popup');
  const initScroll = () => {
    $('#user-followers-popup, #following-users-popup').on('scroll', throttle(function () {
      const $target = $(this);
      const div = $target.get(0);
      if((div.scrollTop + 100) + div.clientHeight >= div.scrollHeight) {
        handleRequest($target);
      }
    },500));
  }

  const handleRequest = (target) => {
    const $preloader = target.find('.js-popup-preload');
    let page = $preloader.attr('data-page');
    const url = $preloader.attr('data-url');
    $.get(url, {
      page: page
    }, function(data) {
      $preloader.closest('.subscribers-popup__content').find('.subscribers-popup__list').append(data.authors);
      if (data.moreAuthors) {
        ++page;
        $preloader.attr('data-page', page);
      } else {
        $preloader.remove();
      }
    });
  }

  if($modal.length && $('.js-popup-preload').length) {
    initScroll();
  }

  $modal.on('click', function(e){
    if ($(e.target).closest('.subscribers-popup__wrap').length) {
      return
    }
    $('body').css('overflow', 'auto');
    $(this).css('display', 'none');
  });
};
