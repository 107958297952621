import './index.sass';

export default () => {
  const $mobileShowMore = $('.header-mobile');

  $('.js-show-screen').on('click', e => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('header-mobile__burger--active');
    $('.header').toggleClass('header--show');
    $('body').toggleClass('no-scroll');

    if($mobileShowMore.hasClass('header-mobile--show-more')) {
      $mobileShowMore.removeClass('header-mobile--show-more')
    }
  });

  $('.js-show-menu').on('click', e => {
    e.preventDefault();
    $mobileShowMore.toggleClass('header-mobile--show-more');
    $(e.currentTarget).toggleClass('header-mobile__link--active');

  })

  $('.js-show-settings').on('click', e => {
    e.preventDefault();
    $('.setting-menu').toggleClass('setting-menu--show');
    $(e.currentTarget).toggleClass('header-mobile__link--active');
    $('body').toggleClass('no-scroll');
  })
}