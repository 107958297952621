import './index.sass';

export default () => {

  // - Open Popup

  $('body').on('click', '.js-login-open, #login-call', function() {
    $('body').css('overflow', 'hidden');
    $('.js-login').fadeIn(400).css('display', 'flex');
    $('.js-login-container').fadeIn(500);
    return false;
  });

  // - Close Popup

  $('body').on('click', '.js-login-close', function() {
    $('body').css('overflow', 'auto');
    $('.js-login').fadeOut(400);
    return false;
  });

  // - Forget Link

  $('body').on('click', '.js-forget-link', function() {
    $('.js-login-container').fadeOut(400);
    $('.js-login-recovery').fadeIn(500).css('display', 'flex');
    $('.js-password-container').fadeIn(500);
    return false;
  });

  // - Return Login

  $('body').on('click', '.js-login-return', function() {
    $('.js-login-container').fadeIn(400);
    $('.js-login-recovery').hide();
    return false;
  });

  // - Login form

  $(document).on('submit', '.login__form', function(e) {
    e.preventDefault();
    const emailField = $(this).find('.input[type="email"]');
    $.ajax({
      url: $(this).attr('action'),
      type: $(this).attr('method'),
      data: $(this).serialize(),
      headers: {
        "X-CSRF-Token": $('meta[name=csrf-token]').attr('content'),
      },
      success: (data) => {
        window.location.href = data.path;
      },
      error: (data)=>{
        emailField.addClass('error');
        emailField.next('.login-error').remove();
        emailField.after('<div class="login-error" >' + data.responseJSON.error + '</div>');
      }
    })
    return false;
  });

  // - Disabled Login Button

  if ($('.js-login').find('.input[type="email"]').val().length === 0 || $('.js-login').find('.input[type="password"]').val().length === 0) {
    $('.js-login-container').find('.login__button').prop('disabled', true);
  }

  // - Validate Email

  $('body').on('input', '.js-login .input[type="email"]', function() {
    if ($(this).val().length >= 1) {
      if ($(this).hasClass('error')) {
        $(this).removeClass('error');
        $(this).next('.login-error').fadeOut(200, function() {
          return $(this).remove();
        });
      }
      if ($('.js-login').find('.input[type="password"]').val().length >= 1) {
        $('.js-login').find('.login__button').prop('disabled', false);
      }
    } else if ($(this).val().length === 0) {
      $('.js-login').find('.login__button').prop('disabled', true);
    }
  });

  // - Validate Password

  $('body').on('input', '.js-login .input[type="password"]', function() {
    if ($(this).val().length >= 1) {
      if ($(this).hasClass('error')) {
        $(this).removeClass('error');
        $(this).next('.login-error').fadeOut(200, function() {
          return $(this).remove();
        });
      }
      if ($('.js-login').find('.input[type="email"]').val().length >= 1) {
        $('.js-login').find('.login__button').prop('disabled', false);
      }
    } else if ($(this).val().length === 0) {
      $('.js-login').find('.login__button').prop('disabled', true);
    }
  });
}
